import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['phone', 'code', 'verifyButton'];

  sendCode(event) {
    let url = `${event.currentTarget.dataset.path}.json`;
    let toast = this.createToast();

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({ mfa_phone: this.phoneTarget.value }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
    .then(r =>  r.json().then(data => ({status: r.status, body: data})))
    .then((response) => {
      if(response.status == 200) {
        this.verifyButtonTarget.disabled = false;
        toast.showToast(response.body.message, true);
      } else if (response.status == 422) {
        this.verifyButtonTarget.disabled = true;
        toast.showToast(response.body.message, false);
      } else throw new Error();
    })
    .catch((response) => {
      this.verifyButtonTarget.disabled = true;
      toast.showToast('Sorry, something went wrong. Please contact system administrator', false);
    });
  }

  verify(event) {
    let url = `${event.currentTarget.dataset.path}.json`;
    let toast = this.createToast();

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({ mfa_phone: this.phoneTarget.value, code: this.codeTarget.value }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
    .then(r =>  r.json().then(data => ({status: r.status, body: data})))
    .then((response) => {
      if(response.status == 200) {
        toast.showToast(response.body.message, true);
        location.reload();
      } else if (response.status == 422) {
        this.verifyButtonTarget.disabled = true;
        toast.showToast(response.body.message, false);
        this.codeTarget.value = '';
      } else throw new Error();
    })
    .catch((response) => {
      this.verifyButtonTarget.disabled = true;
      toast.showToast('Sorry, something went wrong. Please contact system administrator', false);
    });
  }

  createToast() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById('toast-container'),
      'toast'
    );
  }

  get csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container'];

  initialize() {
    document.body.addEventListener('toast.show', (event) => {
      this.showToast(event.detail.message, event.detail.isSuccess)
    })
  }

  showToast(message, isSuccess) {
    this.removeAllExistingToasts();

    let toastsContainer = this.containerTarget;
    let toast = document.createElement('div');

    toastsContainer = toastsContainer.appendChild(toast);

    toast.setAttribute('id', 'toast');
    toast.setAttribute('data-after', message);

    if(isSuccess) {
      toast.classList.add('success');
    } else {
      toast.classList.add('error');
    }

    toast.classList.add('show-toast');

    setTimeout(function() { 
      toast.classList.remove('show-toast');
    }, 5000);
  }

  removeAllExistingToasts() {
    this.containerTarget.innerHTML = '';
  }
}
